<template>
  <div class="vip-info" ref="modal">
    <div class="vip-button" @click="vipButtonClick">
      <!--      <span>升级会员</span>-->
      <a-icon type="crown"/>
    </div>
    <a-modal
        v-if="visible"
        v-model="visible"
        centered
        :footer="null"
        :maskClosable="false"
        :width="1180"
        :getContainer="() => $refs.modal"
        :destroyOnClose="true">
      <div slot="title" class="title">
        <a-avatar style="background:#0275d3">
          <img v-if="avatar" slot="icon" :src="avatar"/>
          <a-icon v-else slot="icon" type="user"/>
        </a-avatar>
        <span class="user-name">{{ name ? name : '暂未登录' }}</span>
        <span class="text">开通会员畅享更多特权，体验极致服务</span>
      </div>
      <Member :height="height" @paySuccess="paySuccess"/>
    </a-modal>

    <LoginModal v-if="login" :modalVisible.sync="login" :reload="false" @loginSuccessCallback="loginSuccessCallback"/>
  </div>
</template>

<script>
export default ({
  computed: {
    height: {
      get() {
        return window.innerHeight - 200
      }
    },
    // name: {
    //   get() {
    //     return sessionStorage.getItem("name")
    //   }
    // },
    // avatar: {
    //   get() {
    //     return sessionStorage.getItem("avatar")
    //   }
    // }
  },
  components: {
    LoginModal: () => import("@/components/Login/LoginModal.vue"),
    Member: () => import("@/views/application/member/Index.vue")
  },
  data() {
    return {
      visible: false,
      login: false,
      name: sessionStorage.getItem("name"),
      avatar: sessionStorage.getItem("avatar")
    }
  },
  methods: {
    vipButtonClick() {
      //要检查下是否登录了
      if (this.$store.getters.token) {
        this.visible = true
      } else {
        this.login = true
        this.$message.warn("请先扫码登录哦")
      }
    },
    loginSuccessCallback() {
      this.visible = true
      this.name = sessionStorage.getItem("name")
      this.avatar = sessionStorage.getItem("avatar")
      this.$emit('loginSuccessCallback')
    },
    paySuccess() {
      this.visible = false
      // this.
    }
  }
})

</script>

<style scoped lang="scss">
@keyframes light {
  0% {
    left: -20px;
  }
  25% {
    left: -20px;
  }
  50% {
    left: 190px;
  }
  75% {
    left: 190px;
  }
  100% {
    left: 190px;
  }
}

.vip-info {

  .vip-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //background: linear-gradient(90deg, #343749, #536976);
    //background: linear-gradient(90deg, #7a6cfc, #f26dbe);
    color: #fff;
    border-radius: 4px;
    padding: 8px 0;
    cursor: pointer;
  }

  //.vip-button::after {
  //  content: "";
  //  height: 40px;
  //  width: 30px;
  //  display: inline-block;
  //  background: hsla(0, 0%, 100%, 0.2);
  //  position: absolute;
  //  left: -60px;
  //  z-index: 2;
  //  transform: skew(-12deg);
  //  animation: light 2.5s linear infinite;
  //}

  ::v-deep.ant-modal-header {
    background: linear-gradient(135deg, #536976, #343749);

    .title {
      display: flex;
      color: #F5F5F5;
      font-weight: normal;
      align-items: center;

      .user-name {
        margin-left: 5px;
        font-size: 15px;
      }

      .text {
        margin-left: auto;
        margin-right: 60px;
        font-size: 13px;
      }
    }
  }

  ::v-deep.ant-modal-close-x {
    color: #BFBFBF;
    line-height: 64px;
  }

  ::v-deep.ant-modal-body {
    background: #f2f2f2;
  }
}


</style>